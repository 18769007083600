import React, { useRef, useEffect } from 'react';
import GLOBE from "vanta/dist/vanta.globe.min";
import BlogFeed from './blog';
import * as THREE from "three";

function BlogApp() {
  const vantaRef = useRef(null);
  const rssFeedUrl = 'https://medium.com/feed/@ksquants'; 

  useEffect(() => {
    if (!vantaRef.current) return;

    const vantaEffect = GLOBE({
      el: vantaRef.current,
      THREE,
      color: 0x0d47a1,
      backgroundColor: 0x000000,
      scale: 1.4,
      scaleMobile: 1.0,
    });

    return () => {
      if (vantaEffect && vantaEffect.destroy) {
        vantaEffect.destroy();
      }
    };
  }, []);

  return (
    <section
      ref={vantaRef}
      className="relative h-auto min-h-screen w-full flex flex-col justify-center items-center overflow-hidden"
    >
      
        <div className="w-full max-w-6xl mx-auto"> {/* Added max-width for better alignment */}
          <BlogFeed rssFeedUrl={rssFeedUrl} />
        </div>
    
    </section>
  );
}

export default BlogApp;
