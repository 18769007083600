import React, { useEffect, useRef, useState } from "react";
import {
  FaChartLine,
  FaDollarSign,
  FaPiggyBank,
  FaPercentage,
  FaCalendarAlt,
  FaArrowUp,
  FaChartArea,
} from "react-icons/fa";
import BackgroundVid from "./assets/bgvid.webm";

const CACHE_KEY = "myfxbook_data";
const CACHE_TIME = 60 * 60 * 1000; // 1 hour

const fetchData = async () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_TIME) {
      return data;
    }
  }

  const API_KEY = process.env.REACT_APP_MYFXBOOK_API_KEY;
  if (!API_KEY) {
    throw new Error("API key is not set");
  }

  const response = await fetch(
    `https://www.myfxbook.com/api/get-my-accounts.json?session=${process.env.REACT_APP_MYFXBOOK_API_KEY}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  localStorage.setItem(
    CACHE_KEY,
    JSON.stringify({
      timestamp: Date.now(),
      data,
    })
  );

  return data;
};

const Background = ({ children, showWidget = true }) => {
  const videoRef = useRef(null);
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    const getAccountData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchData();
        const account = Array.isArray(data.accounts)
          ? data.accounts.find((account) => account.id === 11164432)
          : null;
        if (account) {
          setAccountData(account);
        } else {
          throw new Error("Account not found");
        }
      } catch (error) {
        console.error("Error fetching account data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getAccountData();
  }, []);

  return (
    <section className="relative h-screen w-full flex justify-center items-center overflow-hidden">
      {/* Video Background */}
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src={BackgroundVid} type="video/webm" />
        <source
          src="https://res.cloudinary.com/adiec7/video/upload/v1728749526/3%E6%9C%8811%E6%97%A5_2_.mov.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Card Container with Glass Effect */}
      {showWidget && (
        <div className="relative z-10 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-lg p-6 rounded-lg max-w-lg w-full ml-4 mr-4 sm:ml-2 sm:mr-2 md:mx-4">
          {isLoading ? (
            <p className="text-white">Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : accountData ? (
            <>
              <h2 className="text-2xl font-bold text-center mb-4 text-white">
                {accountData.name}
              </h2>
              <p className="text-left text-sm mb-4 text-white">
                {accountData.description || "No description provided."}
              </p>

              {/* Account Data with Icons */}
              <div className="grid grid-cols-2 gap-4 text-white">
                <div className="flex items-center space-x-2">
                  <FaChartLine className="text-blue-400" />
                  <p>
                    <strong>Gain:</strong> {accountData.gain}%
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaDollarSign className="text-green-400" />
                  <p>
                    <strong>Balance:</strong> ${accountData.balance}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaPiggyBank className="text-yellow-400" />
                  <p>
                    <strong>Equity:</strong> ${accountData.equity}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaPercentage className="text-red-400" />
                  <p>
                    <strong>Drawdown:</strong> {accountData.drawdown}%
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaArrowUp className="text-green-400" />
                  <p>
                    <strong>Daily Growth:</strong> {accountData.daily}%
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaChartArea className="text-blue-400" />
                  <p>
                    <strong>Monthly Growth:</strong> {accountData.monthly}%
                  </p>
                </div>
                {/* <div className="flex items-center space-x-2">
  <FaDollarSign className="text-blue-400" />
  <p><strong>Account Currency:</strong> {accountData.currency}%</p>
</div> */}
                {/* Last Update */}
                <div className="flex items-center space-x-3">
                  <FaCalendarAlt className="text-indigo-600 " />
                  <p>
                    <strong>Last Update:</strong> {accountData.lastUpdateDate}
                  </p>
                </div>
              </div>

              {/* Verified by Myfxbook */}
              <div className="text-center mt-6">
                <p className="text-sm text-gray-300">
                  Verified by{" "}
                  <a
                    href={accountData.invitationUrl || "https://www.myfxbook.com/members/Adiec7/hon-fund/11164432"}
                    target="_blank"
                    className="text-blue-400 "
                    rel="noreferrer"
                  >
                    Myfxbook
                  </a>
                </p>
              </div>
            </>
          ) : (
            <p className="text-white">No account data available.</p>
          )}
        </div>
      )}

      {/* Children content */}
      <div className="relative z-20">{children}</div>

      {/* Optional overlay color for better text visibility */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
    </section>
  );
};

export default Background;
