import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import GLOBE from "vanta/dist/vanta.globe.min";
import Slider from "react-slick";
import "./service.css"; // for custom glass effect

// Card Component with Glass Effect
const ServiceCard = ({ title, description, imageSrc, ctaText, ctaLink }) => {
  return (
    <div className="glass-card p-6 mx-4">
      {imageSrc && <img src={imageSrc} alt={title} className="card-image" />}
      <h3 className="card-title text-white text-2xl font-semibold mt-4">{title}</h3>
      <p className="card-description text-white text-base mt-2 text-left">{description}</p>
      {/* CTA Button */}
      <a 
        href={ctaLink} 
        className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
      >
        {ctaText}
      </a>
    </div>
  );
};

const Services = () => {
  const vantaRef = useRef(null);

  // Initialize Vanta Globe
  useEffect(() => {
    const vantaEffect = GLOBE({
      el: vantaRef.current,
      THREE,
      color: 0x0d47a1, // Globe color
      backgroundColor: 0x000000, // Background color
      scale: 1.4, // Scale of the globe
      scaleMobile: 1.0, // Mobile scale
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  // Sample card data
  const services = [
    { 
      title: "Algorithm Development", 
      description: "We specialize in creating custom algorithmic trading solutions that automate decision-making in the financial markets. Our algorithms are designed to identify market opportunities and execute trades faster than human capability, ensuring accuracy and maximizing profitability. Whether you are a hedge fund, institutional investor, or individual trader, our systems are tailored to your specific needs.",
      ctaText: "Book Consultation",
      ctaLink: "/contact"
    },
    { 
      title: "Fund Management", 
      description: "Our fund management services leverage cutting-edge quantitative models to optimize portfolio performance. We use data analysis and automation to execute trades with precision and agility, ensuring consistent returns while minimizing risk. Partner with us to experience enhanced financial growth through advanced, scientific methods.",
      ctaText: "Learn More",
      ctaLink: "/funds"
    },
    { 
      title: "Quantitative Research & Development", 
      description: "Our team of quantitative researchers is dedicated to developing robust financial models and strategies. We provide in-depth research into market dynamics, backtesting, and optimization to help you make informed investment decisions. Whether you need custom strategy development or analysis of existing models, our R&D services ensure you always have a competitive edge.",
      ctaText: "Contact Us",
      ctaLink: "/contact"
    },
    { 
      title: "Education & Training", 
      description: "We are committed to fostering the next generation of quants. Our education services include comprehensive training in quantitative finance and algorithmic trading. We offer hands-on workshops, courses, and resources that equip students, professionals, and financial institutions with the skills needed to thrive in today’s financial markets.",
      ctaText: "Learn More",
      ctaLink: "/education"
    },
    { 
      title: "Consulting Services", 
      description: "KSQuantitative provides expert consulting services to help you integrate advanced quantitative strategies into your investment approach. Our consultancy covers everything from portfolio optimization to strategy refinement, giving you actionable insights to boost your financial performance.",
      ctaText: "Book Consultation",
      ctaLink: "https://calendly.com/adiec7/consultation"
    },
    { 
      title: "Ready to elevate your trading?", 
      description: "Explore our innovative solutions today, and let KSQuantitative be your partner in success.",
      ctaText: "Get Started",
      ctaLink: "/contact"
    }
  ];

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  // Next and Prev Arrows for custom buttons
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 18l6-6-6-6"></path>
        </svg>
      </div>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M14 18l-6-6 6-6"></path>
        </svg>
      </div>
    );
  }

  return (
    <section
      ref={vantaRef}
      className="relative h-screen w-full flex justify-center items-center overflow-hidden"
    >
      <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center items-center">
        <h1 className="text-4xl text-white font-bold mb-8">Our Services</h1>

        {/* Scrollable Service Cards */}
        <div className="w-full max-w-6xl mx-auto relative">
          <Slider {...sliderSettings}>
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                title={service.title}
                description={service.description}
                imageSrc={service.imageSrc}
                ctaText={service.ctaText}
                ctaLink={service.ctaLink}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Services;
