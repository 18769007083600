import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FiExternalLink } from "react-icons/fi"; // Import the icon

const BlogFeed = ({ rssFeedUrl }) => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get("https://api.rss2json.com/v1/api.json", {
          params: {
            rss_url: rssFeedUrl,
          },
        });

        if (response.status === 200 && response.data.items) {
          const uniqueBlogs = response.data.items.filter((blog, index, self) =>
            index === self.findIndex((b) => b.guid === blog.guid)
          );
          setBlogs(uniqueBlogs);
        } else {
          console.error("Unexpected response format:", response);
          setError(new Error("Failed to fetch blogs."));
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError(new Error("There was an issue fetching the blog posts. Please try again later."));
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [rssFeedUrl]);

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const extractImageUrl = (description) => {
    const match = description.match(/<img.*?src=["'](.*?)["']/);
    return match ? match[1] : null;
  };

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -270 : 270;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <section className="relative h-screen w-full flex justify-center items-center overflow-hidden">
      <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center items-center">
        <h4 className="text-2sm text-white font-bold mb-8">Insights | Stories | Innovations | Trends</h4>

        <div className="w-full max-w-6xl mx-auto relative px-12">
          {isLoading ? (
            <p className="text-white">Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error.message}</p>
          ) : (
            <div className="relative">
              <div
                ref={scrollContainerRef}
                className="overflow-x-auto pb-4 scrollbar-hide"
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              >
                <div className="flex space-x-4" style={{ width: `${blogs.length * 270}px` }}>
                  {blogs.map((blog) => (
                    <div key={blog.guid} className="flex-shrink-0 w-[250px]">
                      <div className="bg-white bg-opacity-10 backdrop-blur-lg border border-gray-100 rounded-lg shadow-md h-[400px] transition-transform transform hover:scale-105 hover:bg-opacity-20 hover:shadow-xl">
                        <img
                          src={
                            blog.thumbnail ||
                            extractImageUrl(blog.description) ||
                            "https://picsum.photos/250/150"
                          }
                          alt={blog.title}
                          aria-label={blog.title}
                          className="object-cover h-36 w-full rounded-t-lg"
                          onError={(e) => (e.target.src = "https://picsum.photos/250/150")}
                        />
                        <div className="p-4 flex flex-col h-[calc(100%-10rem)]">
                          <h2 className="font-bold text-lg text-white mb-2">{blog.title}</h2>
                          <p className="text-gray-300 flex-grow overflow-hidden">
                            {stripHtmlTags(blog.description).slice(0, 100)}
                            {stripHtmlTags(blog.description).length > 100 ? "..." : ""}
                          </p>
                          <a
                            href={blog.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-500 hover:underline mt-2 flex items-center"
                          >
                            Read More
                            <FiExternalLink className="ml-2" /> {/* React Icon */}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Scroll Left Button */}
              <button
                onClick={() => scroll("left")}
                className="absolute -left-4 top-1/2 transform -translate-y-1/2 bg-blue-500 bg-opacity-70 p-3 rounded-full cursor-pointer hover:bg-blue-700 hover:shadow-lg z-10 transition-all"
                aria-label="Scroll left"
              >
                <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                </svg>
              </button>
              {/* Scroll Right Button */}
              <button
                onClick={() => scroll("right")}
                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-blue-500 bg-opacity-70 p-3 rounded-full cursor-pointer hover:bg-blue-700 hover:shadow-lg z-10 transition-all"
                aria-label="Scroll right"
              >
                <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogFeed;
