// App.js
import React from 'react';
import Home from './home'
import Navbar from './navbar';  // Your Navbar file
import AboutUs from './aboutus';
import Services from './service';
import ContactUs from './contact';
import EducationPage from './education';
import FundsPage from './funds';
import BlogApp from './BlogList';
import Background from './myfxbook';
import InvestmentForm from './agreement';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/education" element={<EducationPage/>}/>
        <Route path='/funds' element={<FundsPage/>}/>
        <Route path='/blog' element={<BlogApp/>}/>
        <Route path='/myfxbook' element={<Background/>}/>
        <Route path='/agreement' element={<InvestmentForm/>}/>
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
